<template>
  <div id="app">
    
    <div class="app-content">
      <router-view @gbAction="gbAction"/>
    </div>
    
    

    <div v-show="showBottonNav" class="bottom-nav flex-around">
      <div v-for="(item,key) in icons" :key="key" class="bottom-nav-item" @click="bottomNavKeyChange(key)">
        <img class="botton-nav-icon" v-if="bottomNavKey==key" :src="item.list[1]"/>
        <img class="botton-nav-icon" v-else :src="item.list[0]"/>
        <br/>
        <span class="bottom-nav-name" :class="{on:bottomNavKey==key}">{{ item.name }}</span>
      </div>
      
    </div>

    <div v-if="showLogin" class="fixed-bg show-login-box">
      <LoginBox @loginBoxAct="loginBoxAct"/>
    </div>


    <div v-if="showBottonNav" id="idy_floatdiv" style="position:fixed;display:flex;left:0;bottom:15%;width:30px;border-top-right-radius:6px;border-bottom-right-radius:6px;height:100px;background:#26B941;line-height: 24px;writing-mode:vertical-rl;align-items:center;justify-content:center;font-family:PingFangSC-Regular;font-size:16px;z-index: 9;">
    <router-link to="/askframe"> 问卷调查</router-link>  
    </div>

   
      
    
    
  </div>
</template>

<script>

import LoginBox from './components/LoginBox.vue';
import conf from "@/assets/js/conf"
export default{
  components:{
    LoginBox
  },
 
  data(){
    return {
      icons:[
        {
          list:[
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-home.png",
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-home-on.png"
          ],
          name:"首页",
          path:"/"
        },
        
        {
          list:[
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-lesson.png",
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-lesson-on.png"
          ],
          name:"我的课程",
          path:"/userlesson"
        },
        {
          list:[
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-user.png",
            "https://uieditor.ui.cn/nongren/lessonstatic/img/icon-user-on.png"
          ],
          name:"我的",
          path:"/ucenter"
        }
        
      ],

      bottomNavKey:0,
      showLogin:false,
      
      showBottonNav:false,

      payInfo:{
        code:"",
        lesson_id:0
      }
      
    }
  },
  watch:{
    bottomNavKey(nval){
      console.log("nval",nval)
    },
    "$route.name"(val){
      console.log(val)
      this.setBottonShow()
    }
  },
  mounted(){

   
    
    // 同时有code 和lesson_id 拉起支付
    var query = this.$route.query
    var code = query.code
    var lesson_id = query.lesson_id
    this.payInfo.code = code
    this.payInfo.lesson_id = lesson_id



    if(code&& lesson_id>0){
      this.createOrder()
    }

   

 
    // 1688页面 和问卷调查页面不显示底部导航
    var url = window.location.pathname
    this.icons.forEach((element,key) => {
      if(element.path == url){
        this.bottomNavKey = key
      }
    });


    this.getLoginUser()

    this.setBottonShow()

    // this.wxShare()
  },
  methods:{
    bottomNavKeyChange(val){
    
      if(val>0 && this.$store.state.loginUser.id<1){
        this.showLogin=true
        return false
      }

      this.bottomNavKey = val
      var path = this.icons[val].path
      this.$router.push(path)
    },
    setBottonShow(){
      
      if(["join","joinform","askframe","createip"].indexOf(this.$route.name)>-1){
       
        this.showBottonNav=false
      }else{
   
        this.showBottonNav=true
      }
      console.log(this.showBottonNav)
    },

    getLoginUser(){
      if(window.localStorage.getItem("token")){
        var _this = this
        this.$axios.get(conf.api.loginByToken).then(res=>{
        
          if(res.data.code==1){
            _this.$store.commit("setLoginUser",res.data.data)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
    },
    loginBoxAct(act){
    
      if(act.action=="hideLogin"){
        this.showLogin=false
      }
      
    },
    gbAction(act){
   
      if(act.act=="createOrder"){

      
        if(this.$store.state.loginUser.id>0){
     
          var fullHref = window.location.href
       
          var uriArr = fullHref.split("?")

          var pm="?lesson_id="+act.lesson_id
          if(uriArr.length>1){
            var pmArr = uriArr[1].split("&")
            pmArr.map((ele)=>{
             
              var aa= ele.split("=")
              if(["code","lesson_id"].indexOf(aa[0])==-1){
                if(pm.length>0){
                  pm+="&"+ele
                }else{
                  pm+="?"+ele
                }
                
              }
            })
          }
       
          
          var href = ""
          if(act.lesson_id==8){
            href = "https%3A%2F%2Flesson.newfarmers.top%2Fjoin%3Flesson_id%3D8"
          }else{
            href = encodeURIComponent(uriArr[0]+pm)
          }
          
         

          // this.payInfo.lesson_id=1
          // var href = encodeURIComponent(window.location.href+"?lesson_id="+act.lesson_id)
          var furl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc87f75805a3f7a00&redirect_uri="+href+"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
        

          window.location.href = furl
         
          // 
        
        }else{
          this.showLogin=true
        }
      }

      
    },
    createOrder(){
     
      conf.createOrder(this,this.payInfo)
     
    },
    
  }
}
</script>

<style>
 #app{
  position: relative;
 }
 .app-content{
  padding-bottom: 70px;
 }
 .bottom-nav{
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 99;
  max-width: 800px;
 }
 .bottom-nav-item{
  text-align: center;
 }

 .botton-nav-icon{
  width:20px
 }
 .bottom-nav-name{
  font-size: 12px;
 }

 /* .login-box{
  position: fixed;
  left: ;
 } */

</style>
